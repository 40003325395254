import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Axios from "axios";
import Header from './components/HeaderContainer';
import Navigation from './components/NavigationComponent';
import './app-container.scss';

const DEV_ENV = false;

function App() {
  const [emailAddress, setEmailAddress] = useState();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isAuth, setIsAuth] = useState(DEV_ENV);

  const onSignOut = () => {
    localStorage.removeItem('session_token');
    localStorage.removeItem('user_data');
    localStorage.removeItem('email_address');

    if (!DEV_ENV) window.location.href = `https://id.x2fa.com/sign-out`;
  };

  useEffect(() => {
    const urlQueryParams = new URLSearchParams(window.location.search);
    const tempSessionToken = urlQueryParams.get("session_token") || localStorage.getItem("session_token");

    console.log(tempSessionToken);

    if (tempSessionToken && typeof tempSessionToken === "string") {
      localStorage.setItem("session_token", tempSessionToken);
    } else {
      localStorage.removeItem("session_token");
    }

    if (tempSessionToken) {
      verifySession(tempSessionToken);
    } else {
      if (!DEV_ENV) onSignOut();
    }
  }, []);

  const verifySession = async (tempSessionToken) => {
    if (loadingSubmit) return;

    setIsAuth(false);
    setLoadingSubmit(true);

    try {
      const result = await Axios.post(
        'https://api.x2fa.com/user/validate-session',
        {},
        {
          headers: {
            'Authorization': tempSessionToken,
            'Content-Type': 'application/json',
          },
        },
      );
      
      setLoadingSubmit(false);
    
      const { data: responseData } = result;
      
      const { data: userData } = responseData;

      const { session_token, email_address_verified, email_address } = userData;

      if (!email_address_verified) {
        setIsAuth(false);
        setLoadingSubmit(false);
        onSignOut();
        return;
      }

      localStorage.setItem('session_token', session_token);

      localStorage.setItem('user_data', JSON.stringify(userData));

      localStorage.setItem('email_address', email_address);
      
      setIsAuth(true);

    } catch(error) {
      setIsAuth(false);
      setLoadingSubmit(false);
      onSignOut();
    }
  }

  return (
    <BrowserRouter>
      {isAuth ? (
        <div className="app-container">
          <Header />
          <Navigation />
        </div>
      ) : <></>}
    </BrowserRouter>
  );
}

export default App;
