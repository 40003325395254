import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import './user-profile-container.scss';

const UserProfileContainer = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    const tempUserEmail = window.localStorage.getItem('email_address') || "...";
    setUserEmail(tempUserEmail);
  }, []);

  const navigateToSignOut = () => {
    localStorage.removeItem('session_token');
    localStorage.removeItem('user_data');
    localStorage.removeItem('email_address');
    window.location.href = 'https://id.x2fa.com/sign-out';
  };

  return (
    <div className='user-profile-container'>
      <div className='user-email'>{userEmail}</div>
      <div
        className='sign-out-button'
        onClick={() => navigateToSignOut()}
      >Sign Out</div>
      <Link to="/privacy-policy">Privacy Policy</Link>
      <Link to="/terms-of-service">Terms of Service</Link>

    </div>
  );
}

export default UserProfileContainer;
