import React from 'react';

import './header-container.scss';

function HeaderContainer() {

  return (
    <div className="header-container">

    </div>
  );
}

export default HeaderContainer;
