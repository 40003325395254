import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import UserProfileContainer from '../UserProfileContainer';

import './navigation-component.scss';

const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentRoute, setCurrentRoute] = useState('');
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const updatePath = () => {
    setIsMobileNavOpen(false);
    setCurrentRoute(window.location.pathname);
  }

  useEffect(() => {
    updatePath();
  }, [location]);

  return (
    <div className='navigation-component'>
      <h1>X2FA</h1>
      <div
        className={`menu-item ${currentRoute === '/' ? 'menu-item-active' : '' }`}
        onClick={() => navigate('/')}
      >User Pools</div>
      <div
        className={`menu-item ${currentRoute === '/documentation' ? 'menu-item-active' : '' }`}
        onClick={() => navigate('/documentation')}
      >Documentation</div>
      <div
        className={`menu-item ${currentRoute === '/contact-support' ? 'menu-item-active' : '' }`}
        onClick={() => navigate('/contact-support')}
      >Contact Support</div>
      <UserProfileContainer />
    </div>
  );
};

export default Navigation;
